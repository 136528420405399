import phoneNumbers from '@bold/common/constants/phone-numbers'
import classNames from 'classnames'
import React from 'react'
import Buttons from 'components/Buttons'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon, { type TextWithIconProps } from 'components/TextWithIcon'
import copy from 'constants/copy'
import emails from 'constants/emails'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as enterprise from 'libs/enterprise'
import * as formatPhone from 'libs/format-phone'
import * as user from 'libs/user'
import styles from './Contact.module.scss'
import { ReactComponent as EnvelopeSvg } from './images/envelope.svg'
import { ReactComponent as PhoneSvg } from './images/phone.svg'

type Props = Component

export default function Contact({ className }: Props) {
  const globalContext = useGlobalContext()

  // TODO: to libs/enterprise
  // dedupe with SignUp & UserNav
  const partner = globalContext.partner
  const isGrouper =
    partner === enterprise.GROUPER_KEY || user.isEnterpriseGrouperUser(globalContext.user)
  // const isHumpback =
  //   globalContext.partner === enterprise.HUMPBACK_KEY ||
  //   user.isEnterpriseHumpbackUser(globalContext.user)
  const isOctopus =
    partner === enterprise.OCTOPUS_KEY || user.isEnterpriseOctopusUser(globalContext.user)
  const isOtter = partner === enterprise.OTTER_KEY || user.isEnterpriseOtterUser(globalContext.user)

  const isEnterpriseNotReefOrca =
    enterprise.isClinical(partner) ||
    user.isEnterpriseClinicalUser(globalContext.user) ||
    (enterprise.isFitness(partner) && !enterprise.REEF_ORCA_SOURCES.includes(partner)) ||
    (user.isEnterpriseFitnessUser(globalContext.user) &&
      !user.isEnterpriseReefOrOrcaUser(globalContext.user))
  const email = isEnterpriseNotReefOrca ? emails.ENROLLMENT : emails.DEFAULT

  // TODO:
  // to libs/phone-numbers
  // add formatter for enterprise display phone numbers
  let phoneNumber = phoneNumbers.DEFAULT
  let phoneNumberDisplay = formatPhone.display(phoneNumbers.DEFAULT)

  if (isGrouper) {
    phoneNumber = enterprise.getPhoneNumber(enterprise.GROUPER_KEY)
    phoneNumberDisplay = enterprise.getPhoneNumberDisplay(enterprise.GROUPER_KEY)
  }

  // if (isHumpback) {
  //   phoneNumber = enterprise.getPhoneNumber(enterprise.HUMPBACK_KEY)
  //   phoneNumberDisplay = enterprise.getPhoneNumberDisplay(enterprise.HUMPBACK_KEY)
  // }

  if (isOctopus) {
    phoneNumber = enterprise.getPhoneNumber(enterprise.OCTOPUS_KEY)
    phoneNumberDisplay = enterprise.getPhoneNumberDisplay(enterprise.OCTOPUS_KEY)
  }

  if (isOtter) {
    phoneNumber = enterprise.getPhoneNumber(enterprise.OTTER_KEY)
    phoneNumberDisplay = enterprise.getPhoneNumberDisplay(enterprise.OTTER_KEY)
  }

  const linkProps = {
    color: 'grey',
  }

  const linkTextProps: Partial<TextWithIconProps> = {
    gutterSize: 'xsmall',
  }

  return (
    <Text
      align="left"
      element="div"
      size="large"
      weight="medium"
      className={classNames('Contact', styles.this, className)}>
      <Buttons axis="y" size="large">
        <Link {...linkProps} to={email}>
          <TextWithIcon {...linkTextProps} icon={<EnvelopeSvg />}>
            {email}
          </TextWithIcon>
        </Link>
        <Link {...linkProps} to={phoneNumber}>
          <TextWithIcon {...linkTextProps} icon={<PhoneSvg />}>
            <Row size="xxxsmall">{phoneNumberDisplay}</Row>
            <Text size="medium" weight="normal">
              {copy.SUPPORT_HOURS}
            </Text>
          </TextWithIcon>
        </Link>
      </Buttons>
    </Text>
  )
}
